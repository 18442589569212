import React from 'react';
import { formatPublishDate } from '../utils';
import logo from '../images/logo-text.svg';
import './article-header.scss';

const ArticleHeader = ({ issue, onToggleNavigation }) => {
  const { issue_number, publish_date } = issue.frontmatter;
  return (
    <header className="ArticleHeader">
      <a className="logo" href="https://firstness.org">
        <img src={logo} alt="Firstness Journal Logo" />
      </a>
      <div
        className="info"
        role="button"
        onClick={onToggleNavigation}
        onKeyPress={onToggleNavigation}
        tabIndex={0}
      >
        Issue #{issue_number} · {formatPublishDate(publish_date)}
      </div>
    </header>
  );
};

export default ArticleHeader;
