export const getIssueNodes = (data) => {
  const issueMap = new Map();
  console.log('data', data);
  data.issues.edges.forEach(edge => {
    const { node } = edge;
    const { frontmatter: { issue_number } } = node;
    issueMap.set(issue_number, {
      ...node,
      articles: []
    });
  });
  data.articles.edges.forEach(edge => {
    const { node } = edge;
    const { frontmatter: { issue: issue_number } } = node;
    const issue = issueMap.get(issue_number);
    if (issue) {
      issue.articles.push(node);
    }
  });
  const issues = Array.from(issueMap.values());
  issues.sort((lhs, rhs) => {
    return (rhs.frontmatter.issue_number - lhs.frontmatter.issue_number);
  });
  issues.forEach(issue => {
    issue.articles.sort((lhs, rhs) => {
      return (lhs.frontmatter.sort_order - rhs.frontmatter.sort_order);
    });
  });
  return issues;
};

export const getRecentIssue = (issues) => {
  for (let i = 0; i < issues.length; ++i) {
    const { articles } = issues[i];
    if (articles && articles.length > 0) {
      return issues[i];
    }
  }

  return null;
};

export const getRecentIssueNumberSet = (issues) => {
  const recentIssue = getRecentIssue(issues);
  if (recentIssue) {
    return new Set([
      recentIssue.frontmatter.issue_number
    ]);
  } else {
    return new Set();
  }
};

export const getMarkdownPageNode = (data) => {
  const edge = data.allMarkdownRemark.edges[0];
  if (!edge) {
    return null;
  } else {
    return edge.node;
  }
};

export const formatPublishDate = (publish_date) => {
  return publish_date.split('T')[0];
};
