import React from 'react';
import clsx from 'clsx';
import { Link } from 'gatsby';
import './toc.scss';

const TableOfContents = ({ issue, articleIndex, displayOptions }) => {
  displayOptions = displayOptions || { author: false, source: undefined };
  return (
    <div className="TableOfContents">
      {issue.articles.length > 0 && (
        <ol>
          {issue.articles.map((article, index) => {
            const isCurrent = (articleIndex === index);
            const href = `/issues/${issue.fields.slug}/${article.fields.slug}`;
            return (
              <li
                key={index}
                className={
                  clsx(
                    "article",
                    {
                      "article--current": isCurrent
                    }
                  )
                }
              >
                <Link className="link" to={href} state={{ source: displayOptions.source }}>
                  {article.frontmatter.title}
                </Link>
                {displayOptions.author && (
                  <span> by {article.frontmatter.author}</span>
                )}
              </li>
            );
          })}
        </ol>
      )}
    </div>
  );
};

export default TableOfContents;
