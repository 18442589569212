import React from 'react';
import { Helmet } from 'react-helmet';
import ArticleLayout from '../components/article-layout';
import ArticleHeader from '../components/article-header';
import ArticleFooter from '../components/article-footer';
import ArticleNavigation from '../components/article-navigation';
import TableOfContents from '../components/toc';
import {
  SOURCE_ARTICLE_NAVIGATION_SECTION_TOP,
  SOURCE_INDEX
} from '../constants';
import './article.scss';

export const ArticleTemplate = ({
  location,
  pageContext
}) => {
  const { issue, article, articleIndex } = pageContext;

  const [topNavigationSectionEnabled, setTopNavigationSectionEnabled] = React.useState(
    shouldEnableTopNavigation(location)
  );

  const toggleTopNavigationSection = () => {
    setTopNavigationSectionEnabled(!topNavigationSectionEnabled);
  };

  return (
    <ArticleLayout className="ArticleTemplate">
      <Helmet>
        <title>{`Firstness Journal · Issue #${issue.frontmatter.issue_number} · ${article.frontmatter.title} by ${article.frontmatter.author}`}</title>
      </Helmet>
      <div id="top" style={{height:0}} />
      <ArticleHeader issue={issue} onToggleNavigation={toggleTopNavigationSection} />
      <div className="scroll-top-button">
        <a href="#top">↑&nbsp;Top</a>
      </div>
      {topNavigationSectionEnabled && (
        <NavigationSection
          className="navigation-section navigation-section--top"
          issue={issue}
          articleIndex={articleIndex}
          displayOptions={{
            author: false,
            source: SOURCE_ARTICLE_NAVIGATION_SECTION_TOP
          }}
        />
      )}
      <main>
        <article className="section">
          <h1>{article.frontmatter.title}</h1>
          <div className="contents">
            <div className="author">by {article.frontmatter.author}</div>
            <div className="markdown" dangerouslySetInnerHTML={{ __html: article.html }} />
          </div>
        </article>
      </main>
      <hr />
      <NavigationSection
        className="navigation-section navigation-section--bottom"
        issue={issue}
        articleIndex={articleIndex}
      />
      <ArticleFooter issue={issue} />
    </ArticleLayout>
  );
}

export default ArticleTemplate;

const NavigationSection = ({ className, issue, articleIndex, displayOptions }) => {
  return (
    <div className={className}>
      <TableOfContents issue={issue} articleIndex={articleIndex} displayOptions={displayOptions} />
      <ArticleNavigation issue={issue} articleIndex={articleIndex} displayOptions={displayOptions} />
    </div>
  );
};

function shouldEnableTopNavigation(location) {
  if (location && location.state) {
    switch (location.state.source) {
      case SOURCE_INDEX:
        return true;
      case SOURCE_ARTICLE_NAVIGATION_SECTION_TOP:
        return true;
      default:
        return false;
    }
  }
  return false;
};
