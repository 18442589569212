import React from 'react';
import clsx from 'clsx';
import './article-layout.scss';

const ArticleLayout = ({ className, children }) => {
  return (
    <div className={clsx("ArticleLayout", className)}>
      {children}
    </div>
  );
};

export default ArticleLayout;
