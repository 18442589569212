import React from 'react';
import { Link } from 'gatsby';
import './article-navigation.scss';

const ArticleNavigation = ({ issue, articleIndex, displayOptions }) => {
  displayOptions = displayOptions || { source: undefined };
  if (!Number.isInteger(articleIndex)) { return null; }
  const nextArticle = issue.articles[articleIndex + 1];
  const previousArticle = issue.articles[articleIndex - 1];
  return (
    <div className="ArticleNavigation">
      {nextArticle && (
        <div className="next">
          <Link className="link" to={`/issues/${issue.fields.slug}/${nextArticle.fields.slug}`} state={{ source: displayOptions.source }}>
            Next&nbsp;→
          </Link>
        </div>
      )}
      {previousArticle && (
        <div className="previous">
          <Link className="link" to={`/issues/${issue.fields.slug}/${previousArticle.fields.slug}`} state={{ source: displayOptions.source }}>
            ←&nbsp;Previous
          </Link>
        </div>
      )}
    </div>
  );
};

export default ArticleNavigation;
