import React from 'react';
import './article-footer.scss';

const ArticleFooter = ({ issue }) => {
  const { buy_url } = issue.frontmatter;
  return (
    <footer className="ArticleFooter">
      <nav>
        <a className="subscribe" href="https://firstness.org/rss.xml" target="_blank" rel="noreferrer">Subscribe</a>
        {buy_url ? (
          <a className="buy" href={buy_url} target="_blank" rel="noreferrer">Buy</a>
        ) : (
          <a className="buy" href="https://www.imperiumpress.org/shop" target="_blank" rel="noreferrer">Buy</a>
        )}
      </nav>
    </footer>
  );
};

export default ArticleFooter;
